import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const LatestEpisodes = () => (
    <section className="py-5 text-center container latest-episodes">
      <div className="container">
        <div className="row">
          <div className="col section-header">
            <h1>Latest Episodes</h1>
          </div>
        </div>
        <div className="row py-5">
            <div className="col-12 col-md-6 col-xl-4">
                <Link to="/episodes/night-of-the-creeps">
                    <div className="episode">
                        <div className="episode-wrapper">
                            <h1 className="episode-title">Night of the Creeps</h1>
                            <h2 className="episode-number">37</h2>
                            <div className="episode-body">
                                <p>1986 // Directed by Fred Dekker // Starring Jason Lively &amp; Tom Atkins</p>
                                <p>Bryan and Dave take a good long look at Fred Dekker's, Night of the Creeps and come away with a vastly different view of it than when they watched it as kids. It's a pastiche of 50's and 60's drive in tropes filtered through a 1980's horror movie lens but unfortunately hasn't aged well at all. Thankfully, Tom Atkins is here to save everything. Thrill me!</p>
                            </div>
                            <p className="episode-link">Listen &#62;&#62;</p>
                            <div className="episode-poster">
                                <div className="poster-overlay-50">&nbsp;</div>
                                <StaticImage
                                    src="../images/night-of-the-creeps-poster-sm.jpeg"
                                    quality={95}
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="A poster for the 1978 zombie movie, Night of the Creeps"
                                    className="img-fluid"
                                    placeholder="BLURRED"
                                    objectFit="cover"
                                />
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
                <Link to="/episodes/dawn-of-the-dead">
                    <div className="episode">
                        <div className="episode-wrapper">
                            <h1 className="episode-title">Dawn of the Dead</h1>
                            <h2 className="episode-number">36</h2>
                            <div className="episode-body">
                                <p>1978 // Directed by George Romero // Starring Ken Foree &amp; Gaylen Ross</p>
                                <p>Bryan and Dave celebrate one year of podcasting with a real deep dive into George Romero's paradigm shifting zombie epic, Dawn of the Dead. In the history of horror movies, there are only a few movies that changed the way movies are made and viewed and this is one of them.</p>
                            </div>
                            <p className="episode-link">Listen &#62;&#62;</p>
                            <div className="episode-poster">
                                <div className="poster-overlay-50">&nbsp;</div>
                                <StaticImage
                                    src="../images/dawn-of-the-dead-poster-sm.jpeg"
                                    quality={95}
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="A poster for the 1978 zombie movie, Dawn of the Dead"
                                    className="img-fluid"
                                    placeholder="BLURRED"
                                    objectFit="cover"
                                />
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
                <Link to="/episodes/my-bloody-valentine">
                    <div className="episode">
                        <div className="episode-wrapper">
                            <h1 className="episode-title">My Bloody Valentine (Bonus)</h1>
                            <h2 className="episode-number">35</h2>
                            <div className="episode-body">
                                <p>1981 // Directed by George Mihalka // Starring Paul Kelman &amp; Neil Affleck</p>
                                <p>Dave and Bryan love you and have sent you this Valentine's Day love letter to 1981's Canadian slasher movie classic, My Bloody Valentine. Sure, it's another slice and dice picture from the golden age of slashers but not too many of them paid this much attention to the craft of telling a story.</p>
                            </div>
                            <p className="episode-link">Listen &#62;&#62;</p>
                            <div className="episode-poster">
                                <div className="poster-overlay-50">&nbsp;</div>
                                <StaticImage
                                    src="../images/my-bloody-valentine-poster-sm.jpg"
                                    quality={95}
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="A poster for the 1981 slasher movie, My Bloody Valentine"
                                    className="img-fluid"
                                    placeholder="BLURRED"
                                    objectFit="cover"
                                />
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
        <div className="row">
          <div className="col section-footer">
            <p><Link to="/episodes">All episodes &#62;&#62;</Link></p>
          </div>
        </div>
      </div>
    </section>
)

export default LatestEpisodes