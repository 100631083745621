import * as React from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import Footer from "../components/footer"
import { Seo } from "../components/seo"
import LatestEpisodes from "../components/latest-episodes"
import Subscribe from "../components/subscribe"

const IndexPage = () => (
  <Layout>
    <Header />
    <LatestEpisodes />
    <section className="black-band py-5">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center section-header">
            <h1>What fresh hell is this?</h1>
          </div>
          <div className="col-12 py-5">
            <div className="boxed-in">
              <div className="indent-this py-5">
                <h2>Coming Attractions</h2>
                <div className="py-5"><iframe src="https://podcasters.spotify.com/pod/show/bryan-jude-white/embed/episodes/Trailer---The-Brothers-Grim-Horror-Podcast-e23tj11" height="auto" width="100%" frameborder="0" scrolling="no" title="Bring Me The Axe horror movie podcast trailer"></iframe></div>
                <p>Bring Me The Axe is a bi-weekly podcast for fans of horror movies old and new. The brothers in question are Bryan and Dave White, two dudes who grew up on the spooky New England shores, scouring the horror section of every video store that was within a bike-ride's distance. Where most people see trash, they find treasure. From the classic Universal monsters, to Vincent Price and Christopher Lee, to Jason Voorhees and Freddy Krueger and beyond! The Bring Me Axe Horror Podcast is a celebration of this treasure, a genuine love affair with horror movies and a celebration of cheap thrills.</p>
                <h2>Spoiler Warning</h2>
                <p>Each episode looks at a particular horror movie in depth, from start to finish, offering opinion, analysis, trivia, and a glimpse of what it was like growing up in the era of unquenchable demand for VHS rental. They also spoil the hell out of every movie they cover, so consider this your warning. Luckily, each episode ends with an announcement of what the next episode's movie will be so listeners have a chance to first take it in if they haven't already seen it.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
  </section>
  <Subscribe />
  <Footer />
  </Layout>
)

export default IndexPage

export const Head = () => (
    <Seo />
)